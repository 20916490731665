import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Navbar, Nav, NavDropdown, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { getUserSettings } from 'selectors';
import UserGuideButton from 'components/demo/userGuideButton';
import { notificationActions } from 'pages/notifications/containers/notifications/actions';
import NotificationsDropdown from 'pages/notifications/containers/notifications/components/dropdown';
import { getEdlyServiceUrl, getWordpressBaseUrl, getEcommerceBaseUrl, getHelpUrl, getPanelBackendUrl } from 'helpers';
import { formatRole } from 'helpers/dashboard';
import { getOrganization } from 'selectors';
import MailChimpLogo from 'assets/images/mail-chimp-logo.svg';
import ZendeskLogo from 'assets/images/zendesk-logo.svg';
import GoogleAnalyticsLogo from 'assets/images/google-analytics-logo.svg';
import IcoMenu from 'assets/images/ico-menu.svg';
import IcoMenuMain from 'assets/images/ico-menu2.svg';
import 'components/header/index.scss';
import { useLocation } from 'react-router-dom';

const parse = require('html-react-parser');

const Header = (props) => {
  const {
    organization,
    name,
    email,
    profile_image,
    panel_role = 'panel_restricted',
    services_notifications,
    display_status,
    updateServicesNotificationsStatus,
    handleLogout
  } = props;

  const param = useLocation();

  const wordPressUrl = getEdlyServiceUrl(organization, 'wordpress');
  const lmsUrl = getEdlyServiceUrl(organization, 'lms');
  const studioUrl = getEdlyServiceUrl(organization, 'studio');
  const ecommerceUrl = getEdlyServiceUrl(organization, 'ecommerce');
  const helpUrl = getHelpUrl();
  const mailChimpUrl = getEdlyServiceUrl(organization, 'mailchimp');
  const zenDeskUrl = getEdlyServiceUrl(organization, 'zendesk');
  const googleDataStudioUrl = getEdlyServiceUrl(organization, 'google_data_studio');

  const handleServicesNotificationsDisplayStatus = (notification_id) => {
    updateServicesNotificationsStatus(notification_id, false);
  };

  const notifications = services_notifications.map((notification, index) => {
    return (
      <div key={index} className={display_status[notification.id] ? `alert alert-${notification.type}` : 'hidden'}>
        <FontAwesomeIcon icon={Icons.faExclamationCircle} className="icon" />
        <span className="dismiss" onClick={() => handleServicesNotificationsDisplayStatus(notification.id)}>
          Dismiss <FontAwesomeIcon icon={Icons.faTimes} />
        </span>
        <p>{parse(notification.message)}</p>
      </div>
    );
  });
  const showItem = param.pathname !== '/panel/dashboard' && param.pathname !== '/panel/settings/general';

  return (
    <header className="header">
      <Navbar bg="dark" expand="lg">
        <div className="container-fluid" role="tablist">
          <Navbar.Brand href="/dashboard" className={`${showItem ? '' : 'disable-after'}`}>
            <img src={organization?.current_site_logo} alt={organization?.name} />
          </Navbar.Brand>
          {showItem && (
            <>
              <Navbar.Toggle className="icon-button" aria-controls="basic-navbar-nav">
                <img src={IcoMenuMain} alt="Menu" />
              </Navbar.Toggle>
              <Navbar.Collapse id="edly-navbar">
                <Nav>
                  <a href={lmsUrl} className="nav-link" rel="noopener noreferrer" target="_blank">
                    LMS
                  </a>
                  <a href={studioUrl} className="nav-link" rel="noopener noreferrer" target="_blank">
                    Studio
                  </a>
                  {getWordpressBaseUrl() && (
                    <Fragment>
                      <a href={wordPressUrl} className="nav-link" rel="noopener noreferrer" target="_blank">
                        Discovery
                      </a>
                      <a
                        href={`${wordPressUrl}wp-admin`}
                        className="nav-link"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Discovery Admin
                      </a>
                    </Fragment>
                  )}
                  {getEcommerceBaseUrl() && (
                    <Fragment>
                      <a
                        href={`${ecommerceUrl}courses/`}
                        className="nav-link"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        E-Commerce
                      </a>
                    </Fragment>
                  )}
                  {getHelpUrl() && (
                    <Fragment>
                      <a href={`${helpUrl}`} className="nav-link" rel="noopener noreferrer" target="_blank">
                        Help
                      </a>
                    </Fragment>
                  )}
                </Nav>
              </Navbar.Collapse>
            </>
          )}
          <div className={`additional-nav ${!showItem ? 'desktop-nav' : ''}`}>
            <UserGuideButton />
            <NotificationsDropdown />
            {showItem && (mailChimpUrl !== '/#' || zenDeskUrl !== '/#' || googleDataStudioUrl !== '/#') && (
              <NavDropdown
                className="icon-button"
                id="basic-nav-dropdown"
                title={
                  <span className="tb-span">
                    <img src={IcoMenu} alt="Menu" />
                    <span className="sr-only">Toggle services drop down</span>
                  </span>
                }
              >
                <ul className="help-group">
                  {zenDeskUrl !== '/#' && (
                    <li>
                      <Nav.Link href={zenDeskUrl} target="_blank" rel="noopener noreferrer" className="dropdown-item">
                        <div className="img-holder">
                          <img src={ZendeskLogo} alt="Zendesk" />
                        </div>
                        Zendesk
                      </Nav.Link>
                    </li>
                  )}
                  {mailChimpUrl !== '/#' && (
                    <li>
                      <Nav.Link href={mailChimpUrl} target="_blank" rel="noopener noreferrer" className="dropdown-item">
                        <div className="img-holder">
                          <img src={MailChimpLogo} alt="Mailchimp" />
                        </div>
                        MailChimp
                      </Nav.Link>
                    </li>
                  )}
                  {googleDataStudioUrl !== '/#' && (
                    <li>
                      <Nav.Link
                        href={googleDataStudioUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="dropdown-item"
                      >
                        <div className="img-holder">
                          <img src={GoogleAnalyticsLogo} alt="Google Data Studio" />
                        </div>
                        Google Analytics
                      </Nav.Link>
                    </li>
                  )}
                </ul>
              </NavDropdown>
            )}
            <NavDropdown
              className="user-block"
              id="userInfoOptions"
              title={
                <div className="info">
                  <div className="profile">
                    <img src={profile_image} alt="" height="35" width="35" />
                  </div>
                </div>
              }
              alignRight
            >
              <div className="user-drop">
                <Dropdown.Item className="header">
                  <p>{name}</p>
                  <p>{email}</p>
                  <p>{formatRole(panel_role)}</p>
                </Dropdown.Item>

                <ul>
                  <li
                    style={{ display: panel_role === 'panel_admin' || panel_role === 'super_admin' ? 'block' : 'none' }}
                  >
                    <Dropdown.Item href="/panel/settings/general">Profile</Dropdown.Item>
                  </li>
                  <li>
                    <a href={`${getPanelBackendUrl()}logout`} onClick={handleLogout}>
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
            </NavDropdown>
          </div>
        </div>
      </Navbar>
      <div className={`system-notification ${display_status ? '' : 'hidden'}`}>{notifications}</div>
    </header>
  );
};

function mapStateToProps(state) {
  const organization = getOrganization(state);
  const { services_notifications, display_status } = state.SERVICES_NOTIFICATIONS;
  const { email, name } = getUserSettings(state);

  return {
    organization,
    services_notifications,
    display_status,
    email,
    name
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(notificationActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
